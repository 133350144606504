/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Subtitle, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotomale"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"1sze1wppbci"} style={{"paddingTop":16,"marginBottom":0,"paddingBottom":12.42499542236328}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box ff--3 fs--43" style={{"paddingTop":0}} content={"Maledivy"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"8otskiyixu7"} style={{"paddingTop":30}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/469025a5b4e5437fada410d2482f2b07_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/469025a5b4e5437fada410d2482f2b07_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/469025a5b4e5437fada410d2482f2b07_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/469025a5b4e5437fada410d2482f2b07_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/81103e3e03454a04bac6f4164cd51a00_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/81103e3e03454a04bac6f4164cd51a00_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/81103e3e03454a04bac6f4164cd51a00_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/81103e3e03454a04bac6f4164cd51a00_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/81103e3e03454a04bac6f4164cd51a00_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/a8b8ce54434f470cbc1765aa5b44d4a5_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/a8b8ce54434f470cbc1765aa5b44d4a5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/a8b8ce54434f470cbc1765aa5b44d4a5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/a8b8ce54434f470cbc1765aa5b44d4a5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/a8b8ce54434f470cbc1765aa5b44d4a5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/a8b8ce54434f470cbc1765aa5b44d4a5_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/dec1614b8dc44d1d88f75239df9900b9_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/dec1614b8dc44d1d88f75239df9900b9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/dec1614b8dc44d1d88f75239df9900b9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/dec1614b8dc44d1d88f75239df9900b9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/dec1614b8dc44d1d88f75239df9900b9_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":14.758331298828123,"paddingTop":0}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/62b2a6ef96c346ab9ba7d9d7abbe709c_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/62b2a6ef96c346ab9ba7d9d7abbe709c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/62b2a6ef96c346ab9ba7d9d7abbe709c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/62b2a6ef96c346ab9ba7d9d7abbe709c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/62b2a6ef96c346ab9ba7d9d7abbe709c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/62b2a6ef96c346ab9ba7d9d7abbe709c_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/86b9a4a12c0442bba3da2703f7cb8fbd_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/86b9a4a12c0442bba3da2703f7cb8fbd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/86b9a4a12c0442bba3da2703f7cb8fbd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/86b9a4a12c0442bba3da2703f7cb8fbd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/86b9a4a12c0442bba3da2703f7cb8fbd_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/9fdbbce3151a411490249e868fbd8df0_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/9fdbbce3151a411490249e868fbd8df0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/9fdbbce3151a411490249e868fbd8df0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/9fdbbce3151a411490249e868fbd8df0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/9fdbbce3151a411490249e868fbd8df0_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/4a0d2da64f75427a91fd24d10b82a0b1_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/4a0d2da64f75427a91fd24d10b82a0b1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/4a0d2da64f75427a91fd24d10b82a0b1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/4a0d2da64f75427a91fd24d10b82a0b1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/4a0d2da64f75427a91fd24d10b82a0b1_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":15.95001220703125}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/4ab85b123c114e128afa4311d44a887f_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/4ab85b123c114e128afa4311d44a887f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/4ab85b123c114e128afa4311d44a887f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/4ab85b123c114e128afa4311d44a887f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/4ab85b123c114e128afa4311d44a887f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/4ab85b123c114e128afa4311d44a887f_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d07a1634ea7a439e9d31a5ded91e615d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d07a1634ea7a439e9d31a5ded91e615d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d07a1634ea7a439e9d31a5ded91e615d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d07a1634ea7a439e9d31a5ded91e615d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/d07a1634ea7a439e9d31a5ded91e615d_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/ae06f1ee9e514131aad74baa1e91f5ea_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/ae06f1ee9e514131aad74baa1e91f5ea_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ae06f1ee9e514131aad74baa1e91f5ea_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ae06f1ee9e514131aad74baa1e91f5ea_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/02782c6db4ff4dffa852c875b8eae14e_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/02782c6db4ff4dffa852c875b8eae14e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/02782c6db4ff4dffa852c875b8eae14e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/02782c6db4ff4dffa852c875b8eae14e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/02782c6db4ff4dffa852c875b8eae14e_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}